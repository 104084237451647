import React from "react";

import Container from "./container";
import * as styles from "./donation.module.css";
import DonateForm from "./donate-form";
import Donations from "./donations";

const Donation = ({description, donations}) => {
    if (!description) return null;

    return (
        <Container>
            <div className={styles.row}>
                <div className={styles.column}>
                    <div className={styles.introTextColumn}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: description,
                            }}
                        />
                    </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.donationFormColumn}>
                        <DonateForm/>
                        <h2>Donaties:</h2>
                        <Donations donations={donations}/>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default Donation;
