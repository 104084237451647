import React from "react";
import * as styles from "./donation.module.css";

const Donations = ({ donations }) => {
  if (!donations) return null;
  if (!Array.isArray(donations)) return null;

  const formatter = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
  });

  return (
    <ul className={styles.donationList}>
      {donations.map((donation) => {
        return (
          <li>
            <div className={styles.donationRow}>
              <div className={styles.donationPerson}>
                <div className={styles.donationDate}>{donation.dat}</div>
                <div className={styles.donorName}>
                  {donation.anonymous === false ? donation.name : "Anoniem"}
                </div>
              </div>
              <div className={styles.donationAmount}>
                {formatter.format(donation.donation)}
              </div>
            </div>
            <div className={styles.donorMessageBox}>
              <div
                className={styles.donorMessage}
                dangerouslySetInnerHTML={{
                  __html: donation.message?.childMarkdownRemark?.html,
                }}
              />
            </div>
          </li>
        );
      })}
    </ul>
  );
};
export default Donations;
