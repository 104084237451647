// extracted by mini-css-extract-plugin
export var checkboxContainer = "donation-module--checkboxContainer--36c1f";
export var column = "donation-module--column--889ee";
export var donateButton = "donation-module--donateButton--04d81";
export var donationAmount = "donation-module--donation-amount--14b90";
export var donationDate = "donation-module--donation-date--4a9c6";
export var donationFormColumn = "donation-module--donationFormColumn--4ab08";
export var donationList = "donation-module--donation-list--24dbc";
export var donationPerson = "donation-module--donation-person--90145";
export var donationRow = "donation-module--donation-row--12cd2";
export var donorMessage = "donation-module--donor-message--042b2";
export var donorMessageBox = "donation-module--donor-message-box--398b2";
export var donorName = "donation-module--donor-name--739bd";
export var introTextColumn = "donation-module--introTextColumn--f655f";
export var row = "donation-module--row--8e902";