import React from "react";
import {graphql} from "gatsby";
import get from "lodash/get";

import Layout from "../components/layout";
import Hero from "../components/hero";
import Donation from "../components/donation";

class DonateTemplate extends React.Component {
    render() {
        const [post] = get(this.props, "data.allContentfulSiteInfo.nodes");
        const donations = get(
            this.props,
            "data.allContentfulDonationReaction.nodes"
        );

        return (
            <Layout location={this.props.location}>
                <Hero image={post.frontPageImage?.gatsbyImageData} title={post.title}/>
                <Donation
                    description={post.longerText.childMarkdownRemark.html}
                    donations={donations}
                />
            </Layout>
        );
    }
}

export default DonateTemplate;

export const pageQuery = graphql`
  query DonationQuery {
    allContentfulSiteInfo(
      filter: { contentful_id: { eq: "7L29eJumuApOqDHXDGbB9n" } }
    ) {
      nodes {
        title
        longerText {
          childMarkdownRemark {
            html
          }
        }
        frontPageImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 1180
            quality: 100
          )
        }
      }
    }
    allContentfulDonationReaction(
      limit: 20
      sort: { fields: [dat], order: DESC }
      filter: { donated: { eq: true } }
    ) {
      nodes {
        name
        message {
          childMarkdownRemark {
            html
          }
        }
        dat(formatString: "DD-MM-YYYY")
        anonymous
        donation
      }
    }
  }
`;
