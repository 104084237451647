import React from "react";
import {useForm} from "react-hook-form";

import * as styles from "./donation.module.css";

const DonateForm = () => {
    const {register, handleSubmit} = useForm();
    const onSubmit = (data) => {
        fetch(`/api/form`, {
            method: `POST`,
            body: JSON.stringify(data),
            headers: {
                "content-type": `application/json`,
            },
        })
            .then((res) => res.json())
            .then((body) => {
                console.log(body);
                const url =
                    "https://donate.stripe.com/7sIeWBbaHaMnfAY3cd?locale=nl&client_reference_id=" +
                    body;
                window.location.assign(url);
            });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <label htmlFor="name">Naam</label>
            <input
                id="name"
                type="text"
                {...register("Name", {required: true, maxLength: 80})}
            />

            <label htmlFor="message">Berichtje (optioneel)</label>
            <textarea id="message" {...register("Message", {required: false})} />

            <label className={styles.checkboxContainer} htmlFor="anonymous">
                <input
                    type="checkbox"
                    id="anonymous"
                    name="anonymous"
                    {...register("Anonymous")}
                />
                Vink aan als je anoniem wilt blijven.
            </label>

            <div>
                <button className={styles.donateButton}>DONEER</button>
            </div>
        </form>
    );
};

export default DonateForm;
